const PAST_PROJECTS: any[] = [
	{
		name: 'Soiree',
		description: 'A social media application with a strong emphasis on events, planning, and advertisement. Written in Javascript (AngularJS) and Java (Spring Boot JPA).'
	},
	{
		name: 'Ad-hoc Search Engine',
		description: 'A basic search engine that which contains a survey of retrieval systems and different strategies for indexing. Includes pseudo-relevance feedback, stopping, stemming, and provides sophisticated summaries relevant to any query. Written in Java.'
	},
	{
		name: 'Candidate Searcher',
		description: 'Application which serves to find candidates based on publications and other attributes for a academic program committee. Written in Java.'
	},
	{
		name: 'Tir',
		description: 'A news aggregator which gets a user\'s favorite news sources on a single page with social media capabilities. Written in JavaScript, HTML, and CSS.'
	},
	{
		name: 'Content Delivery Network',
		description: 'Developed a Content Delivery Network (CDN) with a custom HTTP server and DNS server using Amazon Web Services for deployment and runtime. Written in Python.'
	},
	{
		name: 'Interative Training Module',
		description: 'Designed and implemented a interactive training game for Gettysburg College using HTML and JavaScript.'
	},
	{
		name: '3D Billiards',
		description: 'Designed and developed a billiards game with custom assets and textures using the OGRE engine written in C++.'
	},
	{
		name: 'Chat Application',
		description: 'Developed a chat application with expressive features in Java.'
	},
	{
		name: '3D Ray Tracer',
		description: 'Developed a 3D ray tracer from scratch using C++ and OpenGL API.'
	},
	{
		name: 'Online Connect Four',
		description: 'Designed an online Connect Four multiplayer game using MySQL, PHP, JavaScript, and HTML.'
	}
]

export default PAST_PROJECTS;
