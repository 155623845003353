import React from 'react';

import PAST_PROJECTS from './past-projects';

import './project.component.css';

class ProjectComponent extends React.Component {

	render() {

		const listPastProjects = PAST_PROJECTS.map((item: any, iteration: number) => {
			return (
				<div className="past-project-list" key={iteration}>
					<h3>{item.name}</h3>
					<p>{item.description}</p>
				</div>
			)
		});

		return (
			<React.Fragment>
				<div className="container">
					<h2>Past Projects</h2>
					{listPastProjects}
				</div>
			</React.Fragment>
		)
	}
}

export default ProjectComponent;
