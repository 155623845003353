import React from 'react';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faHandshake} from '@fortawesome/free-regular-svg-icons';
import {faPuzzlePiece, faUserAstronaut, faUsers, faCube} from '@fortawesome/free-solid-svg-icons';


import './home.component.css';

class HomeComponent extends React.Component {

  public proficiencyLevels: any = [
		{name: 'Java', proficiency: 90},
		{name: 'Python', proficiency: 80},
		{name: 'Web (HTML/CSS/JavaScript)', proficiency: 90},
		{name: 'C++', proficiency: 75},
		{name: 'PHP', proficiency: 85},
		{name: 'C', proficiency: 70}
	];

  render() {
    const listItems = this.proficiencyLevels.map((item: any, iteration: number) => {
      return (
        <div className="skill-attribute" key={iteration}>
          <p>{item.name}</p>
          <LinearProgress variant="determinate" value={item.proficiency} />
        </div>
      );
    });

    return (
      <React.Fragment>
        <div className="banner-image">
          <div className="banner-text">
            <h1>Nicholas Jared Carugati</h1>
            <p>Software Engineer, Web Developer & Analyst</p>
          </div>
        </div>
        <div className="container sub-heading">
          <div className="attribute-container">
            <Paper className="card-attribute expand">
              <FontAwesomeIcon icon={faUserAstronaut} size="2x" />
              <Typography variant="h4" component="h3"><strong>Self-starter</strong></Typography>
              <Typography component="p">
                Motivated and willing to learn new things outside of the scope of duties.
                Self-taught in numerous languages and willing to go the distance to tackle difficult challenges.
              </Typography>
            </Paper>
            <Paper className="card-attribute expand">
              <FontAwesomeIcon icon={faHandshake} size="2x" />
              <Typography variant="h4" component="h3"><strong>Mentor</strong></Typography>
              <Typography component="p">
                Assisted in teaching over 3 different courses at both undergrad and graduate level institutions.
                Have firsthand experience in software management in numerous projects with numerous leadership roles.
              </Typography>
            </Paper>
            <Paper className="card-attribute expand">
              <FontAwesomeIcon icon={faUsers} size="2x" />
              <Typography variant="h4" component="h3"><strong>Team Player</strong></Typography>
              <Typography component="p">
                Performed numerous tasks in a collaborative setting inside and outside of the workplace and academia. Member of athletic programs which required strenuous team utilization and communication.
              </Typography>
            </Paper>
          </div>
        </div>
        <Divider></Divider>
        <div className="container sub-heading">
      			<div className="card-stat project-container">
      				<Paper className="card-attribute">
      					<FontAwesomeIcon icon={faPuzzlePiece} size="2x" />
                <Typography variant="h4" component="h3">Ongoing Projects</Typography>
      					<Typography variant="h4" component="h3"><strong>2</strong></Typography>
      				</Paper>
      				<Paper className="card-attribute">
      					<FontAwesomeIcon icon={faCube} size="2x" />
      					<Typography variant="h4" component="h3">Finished Projects</Typography>
      					<Typography variant="h4" component="h3"><strong>12</strong></Typography>
      				</Paper>
      			</div>
    				<Paper className="card-stat programming-stat">
    					<h1>Programming Languages</h1>
    					{listItems}
    				</Paper>
      		</div>
      </React.Fragment>
    )
  }
}

export default HomeComponent;
