export const PORTFOLIO_EMPLOYERS: any = [
	{
		image: "/images/vanguard.png",
		title: "The Vanguard Group",
		positions: [
			{
				dates: "September 2021 - Present",
				division: "Retail Systems",
				position: "Developer III",
				description: [
					"Maintained batch jobs, code bases, and mitigated defects for legacy software within the Options Engine",
					"Designed and collaboarated in the development of the options batch rearchitecture for automatic transactions",
					"Assisted in architecture and led project initiatives in the modernization efforts of our product family."
				]
			},
			{
				dates: "January 2020 - September 2021",
				division: "Retail Systems",
				position: "Developer II",
				description: [
					"Maintained batch jobs, code bases, and mitigated defects for legacy software within the Options Engine",
					"Designed and collaboarated in the development of the options batch rearchitecture for automatic transactions",
					"Created cloud infrastructure for product batch programs using Amazon Web Services (AWS)"
				]
			}
		]
	},
	{
		image: "/images/dep.png",
		title: "State of New Jersey Department of Environmental Protection",
		positions: [
			{
				dates: "May 2017 - January 2020",
				division: "General Services and Systems Coordination",
				position: "Software Development Specialist I",
				description: [
					"Collaborated in establishing and amending conventions and standards for software development in the division.",
					"Revised and created employee web applications in the LAMP stack serving thousands of employees.",
					"Assisted in the creation of building management application for the New Jersey State police."
				]
			}
		]
	},
	{
		image: "/images/ccis.png",
		title: "Northeastern University",
		positions: [
			{
				dates: "August 2016 - April 2018",
				division: "College of Computer and Information Sciences",
				position: "Masters Teaching Assistant",
				description: [
					"Providing peer tutoring, support, and grade assignments to ~40-60 students teaching computer applications.",
					"Lead teaching assistant Spring 2017 supervising 10 TAs and overseeing 25 sections of the course."
				]
			}
		]
	},
	{
		image: "/images/ei.png",
		title: "Eisenhower Institute",
		positions: [
			{
				dates: "August 2015 - May 2016",
				division: "Campus Communication Team",
				position: "Web Director",
				description: [
					"Designed and maintained the Eisenhower Institute webpage through content management systems",
					"Provided information and applications to students, fellows, and those interested in political science," +
					"international relations, and policy"
				]
			}
		]
	},
	{
		image: "/images/dep.png",
		title: "State of New Jersey Department of Environmental Protection",
		positions: [
			{
				dates: "May 2013 - August 2014",
				division: "Office of Information and Resource Management",
				position: "Web Developer Intern",
				description: [
					"Maintained regulation information and news related to NJ environmental issues by updating, adding, " +
					"and removing existing website content, data and templates.",
					"Created design templates and web frameworks using ASP .NET, and JavaScript (JQuery)."
				]
			}
		]
	},
	{
		image: "/images/gettysburg.png",
		title: "Gettysburg College",
		positions: [
			{
				dates: "August 2013 - May 2015",
				division: "Department of Computer Science",
				position: "Peer Learning Assistant",
				description: [
					"Provided peer tutoring, support, and graded assignments to 30 students enrolled in 2 computer science courses.",
				]
			}
		]
	},
];
