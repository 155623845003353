import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBitbucket, faLinkedin, faInstagram} from '@fortawesome/free-brands-svg-icons';
import {faEnvelope} from '@fortawesome/free-regular-svg-icons';

import './footer.component.css';

function FooterComponent() {
  return (
    <div className="footer-container">
        	<p className="text-secondary footer-text">Nicholas Carugati {(new Date().getFullYear())}</p>
        	<div className="links-container">
        		 <a className="footer-icon" href="https://bitbucket.org/ncarugati/">
                <FontAwesomeIcon icon={faBitbucket} />
        		 </a>
        		 <a className="footer-icon" href="https://www.instagram.com/njcarugati/">
                <FontAwesomeIcon icon={faInstagram} />
        		 </a>
        		 <a className="footer-icon" href="https://www.linkedin.com/in/nicholas-carugati-419b53113/">
                <FontAwesomeIcon icon={faLinkedin} />
        		 </a>
        		 <a className="footer-icon" href="mailto:nicholas@carugati.co">
                <FontAwesomeIcon icon={faEnvelope} />
        		 </a>
        	</div>
        </div>
  );
}

export default FooterComponent;
