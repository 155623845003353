import React, {Component} from 'react';

import './about.component.css';

class AboutComponent extends Component {
  render() {
    return (
      <React.Fragment>
        <h1 className="heading">About Me</h1>
        <div className="container about-container">
          <img className="portrait" src="/images/portrait.jpg" alt="Me in portrait form" />
          <div className="container">
          	<p>
          			Born and raised in Bucks County Pennsylvania, I started computer programming as a hobby when I was 12 years old.
          			During my humble beginnings, I went from coding widgets for message boards and forums to disassembling and modifying
          			my favorite games. Throughout the years as a hobbyist, I have taught myself a variety of programming languages
          			and fundamental computer science concepts.
          	</p>
          	<p>
          		In 2012, I decided to take all of my knowledge and passion for software development and turn it into a profession.
          		During my professional career, I have expanded my understanding in this field professionally at an exponential rate and
          		am determined to keep growing personally and professionally.
          	</p>
          	<p>
          		Outside of my profession, I have played the Piano and have participated in jazz and classical ensembles.
          		I enjoy mixing the music I make and taking whatever knowledge I learn in computer science and applying it to music.
          		Outside of music, I also run long distance. I was in the cross country, and track and field programs form elementary school
          		until college.  After my competitive career, I continue to run races alongside my former teammates.
          		Another activity I have also partaken in casually is fencing.  Other things I do as a past time include video games,
          		shoe collecting, and restoration, investing, and of course, programming even after work hours stays in my repertoire.
          	</p>
          	<p>
          		Feel free to take a look around my website. All of my contact and social media information is in the footer of the site.
          		For any freelancing or contracting inquiries, please contact me through my email.
          	</p>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default AboutComponent;
