import React from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';

import HomeComponent from './workflows/home/home.component';
import AboutComponent from './workflows/about/about.component';
import PortfolioComponent from './workflows/portfolio/portfolio.component'
import ProjectComponent from './workflows/project/project.component';
import NavigationComponent from './components/navigation.component';
import FooterComponent from './components/footer.component';

import './app.component.css';

class AppComponent extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Router>
           <NavigationComponent></NavigationComponent>
           <Route path="/" exact component={HomeComponent} />
           <Route path="/home" component={HomeComponent} />
           <Route path="/about/" component={AboutComponent} />
           <Route path="/portfolio/" component={PortfolioComponent} />
           <Route path="/project/" component={ProjectComponent} />
           <FooterComponent></FooterComponent>
        </Router>
        </React.Fragment>
    )
  }
}

export default AppComponent;
