import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';

import {PORTFOLIO_INSTITUTIONS} from './portfolio-institutions';
import {PORTFOLIO_EMPLOYERS} from './portfolio-employers';
import {PORTFOLIO_CERTIFICATIONS} from './portfolio-certifications';
import {PORTFOLIO_LANGUAGES, PORTFOLIO_FRAMEWORKS, PORTFOLIO_APPLICATIONS} from './portfolio-technical';

import './portfolio.component.css';

class PortfolioComponent extends React.Component {

	render() {

		const institutionView = PORTFOLIO_INSTITUTIONS.map((institution: any, iteration: number) => {

			const descriptionView = institution.description.map((desc: string, iterator: number) => {
				return (
					<div className="list-info-header" key={iterator}>
						<div className="content-spacing">
							{desc}
						</div>
					</div>
				);
			});

			return (
				<div className="padding-tile" key={iteration}>
					<div className="tile-content">
						<div className="emblem">
							<img src={institution.image} alt="Institution" />
						</div>
						<div className="list-info">
							<div className="list-info-header">
								<div>
									<h3>{institution.title}</h3>
									<h4>{institution.dates}</h4>
								</div>
								<div>
									<h4>{institution.division}</h4>
								</div>
							</div>
							{descriptionView}
						</div>
					</div>
				</div>
			)
		});

		const certificationView = PORTFOLIO_CERTIFICATIONS.map((certification: any, iteration: number) => {

			return (
				<div className="padding-tile" key={iteration}>
					<div className="tile-content">
						<div className="emblem">
							<img src={certification.image} alt="Institution" />
						</div>
						<div className="list-info">
							<div className="list-info-header">
								<div>
									<h3>{certification.title}</h3>
									<strong>Issued: </strong>{certification.issued}
								</div>
								<div>
									<strong>Expires: </strong>{certification.expiration}
								</div>
								<div>
									<strong>Credential: </strong><a href={certification.credentialUrl}>{certification.credentialUrl}</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		});

		const employerView = PORTFOLIO_EMPLOYERS.map((employer: any, iterator: number) => {
			const positionsView = employer.positions.map((position: any, iterator: number) => {
				const descriptionView = position.description.map((desc: string, iterator: number) => {
					return (
						<div key={iterator}>
							{desc}
						</div>
					);
				});

				return (
					<React.Fragment key={iterator}>
						<div>
							<span className="block"><strong>Department/Division:</strong> {position.division}</span>
							<span className="block"><strong>Role: </strong>{position.position}</span>
							<span className="block"><strong>Dates: </strong>{position.dates}</span>
						</div>
						<p>{descriptionView}</p>
					</React.Fragment>
				)
			});


			return (
				<div className="padding-tile" key={iterator}>
					<div className="tile-content" >
						<div className="emblem">
							<img src={employer.image} alt="Employer" />
						</div>
						<div className="list-info">
							<div className="list-info-header">
								<div>
									<span className="employer-title">{employer.title}</span>
								</div>
								{positionsView}
							</div>
						</div>
					</div>
				</div>
			)
		});

		const languageView = PORTFOLIO_LANGUAGES.map((language: any, iteration: number) => {
			return (
				<Tooltip title={language.name + ' Proficiency: ' + language.proficiency} key={iteration}>
					<img width="64px" height="64px" src={language.url} alt="Language" />
				</Tooltip>
			)
		});

		const softwareView = PORTFOLIO_FRAMEWORKS.map((framework: any, iteration: number) => {
			return (
				<Tooltip title={framework.name} key={iteration}>
					<img width="64px" height="64px" src={framework.url} alt="Framework" />
				</Tooltip>
			)
		});

		const applicationsView = PORTFOLIO_APPLICATIONS.map((application: any, iteration: number) => {
			return (
				<Tooltip title={application.name} key={iteration}>
					<img width="64px" height="64px" src={application.url} alt="Application" />
				</Tooltip>
			)
		});

		return (
			<div className="container">
				<h1 className="heading">Portfolio</h1>
				<div className="section-container">
					<div className="pf-header">
						<span className="pf-header-content">Education</span>
					</div>
					<div>
						{institutionView}
					</div>
				</div>
				<div className="section-container">
					<div className="pf-header">
						<span className="pf-header-content">Certifications</span>
					</div>
					<div>
						{certificationView}
					</div>
				</div>
				<div className="section-container">
					<div className="pf-header">
						<span className="pf-header-content">Professional Experience</span>
					</div>
					<div>
						{employerView}
					</div>
				</div>
				<div className="section-container">
					<div className="pf-header">
						<span className="pf-header-content">Technical Knowledge</span>
					</div>
					<div className="skill-cover">
						<div>
							<strong>Programming Languages</strong>
							<div className="skill-display">
								{languageView}
							</div>
						</div>
						<div>
							<strong>Software &amp; Frameworks</strong>
							<div className="skill-display">
								{softwareView}
							</div>
							<strong>Applications</strong>
							<div className="skill-display">
								{applicationsView}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default PortfolioComponent;
