export const PORTFOLIO_LANGUAGES: any = [
	{ name: "Java", proficiency: "95", url: '/images/lang/java.png' },
	{ name: "C++", proficiency: "70", url: '/images/lang/cpp.png' },
	{ name: "C Language", proficiency: "70", url: '/images/lang/c.png' },
	{ name: "JavaScript", proficiency: "95", url: '/images/lang/js.png' },
	{ name: "PHP", proficiency: "80", url: '/images/lang/php.png' },
	{ name: "MySQL", proficiency: "85", url: '/images/lang/mysql.png' },
	{ name: "Python", proficiency: "90", url: '/images/lang/py.png' },
	{ name: "Bash", proficiency: "85", url: '/images/lang/sh.png' },
	{ name: "TypeScript", proficiency: "90", url: '/images/lang/ts.png' },
];

export const PORTFOLIO_FRAMEWORKS: any = [
	{ name: 'NodeJS', url: '/images/apis/njs.png' },
	{ name: 'NPM', url: '/images/apis/npm.png' },
	{ name: 'MongoDB', url: '/images/apis/mongo.png' },
	{ name: 'Angular', url: '/images/apis/angular.png' },
	{ name: 'Zend Framework', url: '/images/apis/zf.png' },
	{ name: 'ReactJS', url: '/images/apis/react.png'}
];

export const PORTFOLIO_APPLICATIONS: any = [
	{ name: 'Microsoft Office', url: '/images/apis/office.png' },
	{ name: 'Eclipse', url: '/images/apis/eclipse.png' },
	{ name: 'Adobe Dreamweaver', url: '/images/apis/dw.png' },
	{ name: 'XCode', url: '/images/apis/xcode.png' },
	{ name: 'NetBeans', url: '/images/apis/netbeans.png' },
	{ name: 'JIRA', url: '/images/apis/jira.png' },
	{ name: 'Amazon Web Services', url: '/images/apis/aws.png' },
	{ name: 'WebStorm', url: '/images/apis/webstorm.png' }
];
