export const PORTFOLIO_INSTITUTIONS: any = [
	{
		image: "/images/neu.png",
		title: "Northeastern University",
		dates: "August 2016 - May 2018",
		division: "College of Computer and Information Science",
		description: [
			"Master of Science in Computer Science",
			"Concentration in: Software Engineering",
			"Relevant Courses: Programming Design Paradigms, Fundamentals of Computer Networks, " +
			"Web Development, Managing Software Development, Database Management Systems, Algorithms, " +
			"Information Retrieval, Advanced Software Development, Database Management Systems"
		]
	},
	{
		image: "/images/gettysburg.png",
		title: "Gettysburg College",
		dates: "August 2012 - May 2016",
		division: "",
		description: [
			"Bachelor of Science in Computer Science",
			"Relevant Courses: Computer Graphics, Operating Systems, Computer Networks, " +
			"Game Engine Design, Databases, Parallel and Distributed Processing, Advanced System Design, " +
			"Digital Forensics",
			"Activities: Varsity Cross Country, Varsity Track & Field, Fencing Club"
		]
	}
];
