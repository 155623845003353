import React from "react";
import {withStyles, Theme, createStyles} from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {Link} from "react-router-dom";

import NavigationTabProps from './navigation.props';

const NavigationTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8"
  },
  indicator: {
    backgroundColor: "transparent"
  }
})(Tabs);

const NavigationTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      textTransform: "none",
      minWidth: 72,
      fontWeight: theme.typography.fontWeightMedium,
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.05)",
        opacity: 1
      },
      "&$selected": {
        backgroundColor: "rgba(0, 0, 0, 0.05)",
      }
    },
    selected: {}
  })
)((props: NavigationTabProps) => <Tab {...props} />);

function NavigationComponent() {
  const [value, setValue] = React.useState(0);

  function handleChange(event: React.ChangeEvent<{}>, newValue: number) {
    if(event) {
      setValue(newValue);
    }
  }

  return (
    <NavigationTabs value={value} onChange={handleChange} centered>
      <NavigationTab label="Home" component={Link} to="/" />
      <NavigationTab label="About" component={Link} to="/about" />
      <NavigationTab label="Portfolio" component={Link} to="/portfolio" />
      <NavigationTab label="Project" component={Link} to="/project" />
    </NavigationTabs>
  );
}

export default NavigationComponent;
